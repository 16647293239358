import { BaseComponent } from '../../atoms/BaseComponent';

class DropdownNavMenu extends BaseComponent {
    render() {
        this.innerHTML = `
            <button class = "header-menu-burger">
                <img src = "/menu-wide.svg" />
            </button>
            <div class = "menu-dropdown ${this.state.visible ? 'menu-dropdown_opened' : ''}">
                <div class = "menu-dropdown__item">
                    <a href="#block-platform">Platform</a>
                </div>
                <div class = "menu-dropdown__item">
                    <a href="#block-ui">How it Works</a>
                </div>
                <div class = "menu-dropdown__item">
                    <a href="#block-mission">
                        Mission
                    </a>
                </div>
                <div class = "menu-dropdown__item">
                    <a href = "#block-help">
                        Help Others
                    </a>
                </div>
            </div>
        `

        const b = document.querySelector('.header-menu-burger');
        b.addEventListener('click', (e) => {
            e.stopPropagation();
            this.state.visible = !this.state.visible;
            this.render();
        })
    }

    connectedCallback() {
        this.state = {
            visible: false
        }
        this.render();

        document.addEventListener('click', (e) => {
            if (!this.state.visible) return;
            const m = document.querySelector('.menu-dropdown');
            if (m) {
                if (m.contains(e.target) || m == e.target) {
                    return;
                }

                this.state.visible = false;
                this.render();
            }
        })
    }
}


customElements.define('dropdow-nav-menu', DropdownNavMenu);
